import React from 'react';
import styled from 'styled-components';
import DefaultLayout from '../layouts/DefaultLayout';
import rccIcon from '../assets/content-icons/indication-icons-rcc.png';
import { Body, DesktopOnly, H3, H4, H3Style, H4Style, H6, HeaderH5Style, Reference, Ul, MobileOnly, PreviewTitleStyle, TableSpan } from '../util/typography';
import hero from '../assets/hero-banners/rcc-image-desktop.png';
import PageIntroduction from '../components/PageIntroduction';
import Section from '../components/Section';
import Subsection from '../components/Subsection';
import * as colors from '../util/colors';
import Chart from '../components/Chart';
import mobileChart from '../assets/content-icons/rcc-chart-mobile.png';
import desktopChart from '../assets/content-icons/rcc-chart-desktop.png';
import Table from '../components/Table';
import PrimaryCallout from '../components/PrimaryCallout';
import downloadIcon from '../assets/utility/icons/icon-button-download.svg';
import desktopEfficacyChart from '../assets/content-icons/rcc-efficacy-desktop.png';
import mobileOrrChart from '../assets/content-icons/rcc-orr-mobile.png';
import desktopOrrChart from '../assets/content-icons/rcc-orr-desktop.png';
import modilePdImage from '../assets/content-icons/pd-mobile.png';
import commonArDesktop from '../assets/content-icons/common-ar-desktop.png';
import commonArMobile from '../assets/content-icons/common-ar-mobile.png';
import rccOsIcon from '../assets/content-icons/rcc-os-icon.png';
import rccOsChartDesktop from '../assets/content-icons/rcc-os-chart-desktop.png';
import rccOsChartMobile from '../assets/content-icons/rcc-os-chart-mobile.png';
import RelatedContent from '../components/RelatedContent';

import { respondTo } from '../util/breakpoints';
import SecondaryCallout from '../components/SecondaryCallout';
import TextIcon2Column from '../components/TextIcon2Columns';
import { jobCodes } from '../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `Renal Cell Carcinoma Clinical Trial Results`,
    keywords: `renal cell carcinoma clinical trial results, keynote 581/clear trial`,
    description: `Health care professionals can review clinical trial results in adult patients with advanced renal cell carcinoma.`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/advanced-renal-cell-carcinoma/","@type":"ApprovedIndication","name":"KEYTRUDA + LENVIMA","alternateName":"pembrolizumab + lenvatinib","description":"KEYTRUDA, in combination with LENVIMA, is indicated for the first-line treatment of adult patients with advanced renal cell carcinoma (RCC)."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/advanced-renal-cell-carcinoma/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/advanced-renal-cell-carcinoma/","@type":"MedicalWebPage","name":"Adverse reactions in the KEYNOTE-581/CLEAR trial","description":"The safety of KEYTRUDA + LENVIMA was investigated in the KEYNOTE-581/CLEAR trial in patients treated with KEYTRUDA + LENVIMA (n=352) compared to sunitinib (n=340) at the protocol-specified interim analysis."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/advanced-renal-cell-carcinoma/","@type":"MedicalTrial","name":"KEYNOTE-581/CLEAR Trial","description":"In the KEYNOTE-581/CLEAR trial: KEYTRUDA + LENVIMA demonstrated superiority in PFS, OS, and ORR vs sunitinib at protocol-specified interim analysis.","trialDesign":"The KEYNOTE-581/CLEAR trial: A multicenter, randomized, open-label, phase 3 trial with 1,069 patients"}`, `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/advanced-renal-cell-carcinoma/","@type":"MedicalEntity","guideline":"National Comprehensive Cancer Network® (NCCN®) recommends pembrolizumab (KEYTRUDA) + lenvatinib (LENVIMA) as a PREFERRED first-line therapy option (CATEGORY 1) for advanced clear cell renal cell carcinoma. ","recognizingAuthority":"National Comprehensive Cancer Network® (NCCN®) "}`,
    ]
}

const jobCode = jobCodes[1].jobCode;

const HeroImage = styled.img`
    max-width: 1032px;
    margin: 0 auto;
    display: block;
`;

const PageIntro = styled(PageIntroduction)`
    font-weight: 400;
    padding-bottom: 20px;
    ${respondTo.md`
        padding-bottom: 30px;
    `}
`;

const SectionIntro = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    i {
        letter-spacing: 1.2px;
    }
`;

const Subtitle = styled.h5`
    ${HeaderH5Style};
    color: ${colors.brandGreen};
    line-height: 18px;
`;

const RccFootnote = styled(Reference)`
    display: flex;
    flex-direction: column;
    gap: 5px;
    i {
        display: contents;
    }
    sup {
        display: flex;
        flex: 0 0 6px;        
        justify-content: center;
    }
`;

const TableRow = styled.div`
    display: flex;
    padding: 15px 10px;
    align-items: center;
    gap: 20px;
    > * {
        flex: 1 1 108px;
        &:first-child {
            flex: 0 0 108px;
        }
    }
    ${respondTo.md`
        padding: 0;
        gap: 0;
        &:nth-child(2n + 1){
            background: ${colors.white};
        }
        > * {
            padding: 18px 30px;
            flex: 1 1 345px;
            &:first-child {
                flex: 0 0 345px;
            }
        }
    `}
    &:has([class*='__Reference']){
        background: ${colors.white};
        padding: 20px;
    }
    [class*='__Reference'] {
        flex-basis: 100%;
        ${respondTo.md`
            padding: 0;
        `}
    }
`;

const TableColumns = styled.div`
    display: flex;
    gap: 5px;
    width: 100%;
    flex-direction: column;
    padding: 0;
    ${respondTo.md`
        padding: 18px 30px;
        gap: 10px;
        flex-direction: row;
        > * {
            flex: 0 0 135px;
        }
        &.second-cell-expanded {
            > :nth-child(2){
                flex: 0 0 175px;
            }
        }
    `}
`;

const EfficacyTableRow = styled.div`
    display: flex;
    padding: 0;
    > * {
        padding: 20px 5px 20px 10px;
        flex: 1 1 90px;
        &:first-child {
            flex: 0 0 90px;
            font-weight: 700;
        }
    }
    ${respondTo.md`
        &:nth-child(2n + 1){
            background: ${colors.white};
        }
        > * {
            padding: 18px 30px;
            flex: 1 1 342px;
            &:first-child {
                flex: 0 0 342px;
            }
            &.full-width {
                flex: 1 1 100%;
                text-align: center;
                font-weight: normal;
                padding: 20px;
            }
        }
    `}
    &:has([class*='__Reference']){
        background: ${colors.white};
        padding: 20px;
    }
    [class*='__Reference'] {
        flex-basis: 100%;
        padding: 0;
        font-weight: 400;
    }
`;

const EfficacyTableColumns = styled.div`
    display: flex;
    gap: 10px;
    width: 100%;
    padding: 15px 5px;
    align-items: center;
    > * {
        text-align: center;
        flex: 1 0 85px;
    }
    ${respondTo.md`
        padding: 18px 30px;
        > * {
            flex: 1 0 280px;
        }
    `}
`;

const PDTableColumns = styled.div`
    display: flex;
    gap: 10px;
    width: 100%;
    padding: 18px 30px;
    > * {
        text-align: center;
        flex: 1 0 183px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const RccTable = styled(Table)`
    h6{
        padding: 20px 10px;
        text-align: center;
        border-bottom: 1px solid ${colors.pebble};
        ${respondTo.md`
            padding: 30px;
        `}
    }
`;

const RccPrimaryCallout = styled(PrimaryCallout)`
    a img.userIcon {
        width: 12.3px;
        height: 16px;
    }
    &:before {
        border-left: 5px solid ${colors.mulberry};
        background: linear-gradient(96deg, ${colors.brandGreen} 47.42%, ${colors.greenGradient} 100%);
    }
    &.noBorder {
        padding-left: 20px;
        ${respondTo.md`
            padding-left: 0px;
        `}
    }
    &.noBorder:before {
        content: none;
    }
`;

const RccEfficacyIntro = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    h3 {
        ${H4Style};
        ${respondTo.md`
            ${H3Style};
        `}
    }
`;

const RCCEfficacyCallout = styled(PrimaryCallout)`
    [class*='PrimaryCallout__Title-']{
        font-weight: 400;
    }
    i {
        letter-spacing: 1.2px;
    }
    &:before {
        border-left: 5px solid ${colors.mulberry};
        background: linear-gradient(96deg, ${colors.brandGreen} 47.42%, ${colors.greenGradient} 100%);
    }
`;

const OrrChart = styled(Chart)`
    [class*='__ChartTitle']{
        width: 100%;
    }
    ${respondTo.md`
        &.orr {
            img {
                width: 75%;
                margin: 0 auto;
                display: block;
            }
        }
    `}
`;

const EndoTable = styled(Table)`
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    border: 1px solid ${({type}) => type === 'keytruda' ? colors.brandGreen : type === 'lenvima' ? colors.mulberry : colors.lightGrey};
    h6{
        text-align: center;
    }
      
    > *:first-child {
        padding: 20px 10px;
        background: ${colors.white};
        border-bottom: 1px solid ${({type}) => type === 'keytruda' ? colors.brandGreen : type === 'lenvima' ? colors.mulberry : colors.lightGrey};
        ${respondTo.md`
             padding: 30px;
        `}
     }

    [class*='__ShadedTableRow'] {
        background: ${colors.white};
    }

    [class*='__ShadedTableColumn'] > *{
        border-bottom: 1px solid ${colors.mist};
    }

    [class*='__ShadedTableColumn']:last-child > * {
        &:last-child{
            border-bottom: 0;
        }
    }

    [class*='__ShadedTableColumn']:nth-child(1) > :nth-child(2n){
        background: ${colors.white};
    }

    [class*='__ShadedTableColumn']:nth-child(3) > :nth-child(${({noOfCells}) => noOfCells === 'odd' ? '2n + 1' : '2n'}){
        background: ${colors.white};
    }

    ${respondTo.md`
        [class*='__ShadedTableColumn']:nth-child(1) > :nth-child(2n){
            background: ${colors.white};
        }
        [class*='__ShadedTableColumn']:nth-child(3) > :nth-child(2n){
            background: ${colors.white};
        }
        [class*='__ShadedTableColumn'] > * {
            &:last-child{
                border-bottom: 0;
            }
        }
    `}

`;

const ShadedTableRow = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0;
    .divider  {
        display: none;
    }
    > :nth-child(3){
        > :last-child {
            border-radius: 0 0 8px 8px;
        }
    }
    .last-element-hidden{
        border-radius: 0 0 8px 8px;
        border-bottom: 0;
    }
    ${respondTo.md`
        flex-direction: row;
        .divider  {
            display: block;
            background: white;
            width: 10px;
            height: 100%;
            border: 1px solid white;
            min-height: 55px;
        }
        > :nth-child(1){
            > :last-child {
                border-radius: 0 0 0px 8px;
            }
        }
        > :nth-child(3){
            > :last-child {
                border-radius: 0 0 8px 0px;
            }
        }
        .last-element-hidden{
            border-radius: 0;
            border-bottom: 1px solid ${colors.mist};
        }
    `}
`;

const ShadedTableColumn = styled.div`
    width: 100%;
    p {
        padding-top: 18px;
        padding-bottom: 18px;
        padding-left: 30px;
        padding-right: 30px;
    }
    ${respondTo.md`
        width: 50%; 
    `}
`;

const PdChart = styled(Chart)`
    [class*='__MobileOnly-']{
        display: flex;
        img{
            border-radius: 0 0 6px 6px;
            padding: 18px 0 0;
        }
    }

    [class*='__ChartTitle']{
        padding: 20px 10px;
    }

    [class*='__ChartChildren']{
        padding: 0;
        [class*='__Body']{
            &.full-width {
                padding: 18px;
                text-align: center;
                border-top: 1px solid ${colors.mist};
            }
        }
    }
`;

const ARSection = styled(Section)`

    [class*='__SubsectionContent'] ~ [class*='__SubsectionContent']{
        ${respondTo.md`
            margin-top: 40px;
            padding: 40px 30px 0 30px;
        `}
    }

`;

const FDASection = styled(Section)`
    padding-bottom: 0;
    [class*='__SubsectionContent-']{
        padding-top: 0;
    }
    h6 {
        font-weight: 400;
    }
`;

const NccnCallout = styled(SecondaryCallout)`
    ${PreviewTitleStyle};
    margin: 0 10px 10px 0;
    text-align: center;
    [class*='SecondaryCallout__Title-']{
        font-weight: 400;
    }
`;

const NccnTableMobile = styled(RccTable)`
    [class*='__EfficacyTableRow']{
        > * :first-child {
            flex: 0 0 115px;
        }
        align-items: center;
    }
    [class*='__EfficacyTableColumns']{
        > * {
            flex: 1 0 120px;
        }
    }
`;

const RccReference = styled(Subsection)`
    padding: 40px 20px 0 20px;
    ${respondTo.md`
        padding: 20px 30px 0 30px;
    `}
`;

const NccnSection = styled(Section)`
    [class*='__RccFootnote']{
        div {
            p {
                display: block;
            }
            sup {
                display: inline;
            }
        }
    }
`;

const OSSection = styled.div`
    display: flex;
    gap: 20px;
    flex-direction: column;
    [class*='__TextIcon2ColumnContent']{
        img {
            height: 190px;
            margin: 0 auto;
        }
    }
    i {
        letter-spacing: 1.2px;
    }
`;

const OSContent = styled.div`
    display: flex;
    gap: 10px;
    flex-direction: column;
`;

const AnchorNavLinks = [
    {
        title: 'Study Design and Baseline Characteristics',
        href:'#study-design',
    },
    {
        title: 'Efficacy',
        href:'#efficacy',
    },
    {
        title: 'Adverse Reactions',
        href:'#adverse-reaction',
    },
    {
        title: 'NCCN Recommendation',
        href: '#nccn-recommendation'
    }
];

const RCLinks = [
    {
        eyebrow: 'Safety',
        title: 'Monitor and help manage adverse reactions',
        to: '/safety'
    },
    {
        eyebrow: 'Dosing',
        title: 'Learn more about dosing and administration',
        to: '/dosing'
    },
];

const Rcc = ({location}) => {
    return (
        <DefaultLayout location={ location } name='KEYNOTE-581/CLEAR Trial in Advanced Renal Cell Carcinoma: Efficacy and Safety Results' icon={rccIcon} anchorNavLinks={AnchorNavLinks} pageMeta={pageMeta} jobCode={jobCode}>
            <DesktopOnly>
                <HeroImage src={hero} alt='' />
            </DesktopOnly>
            <PageIntro>
                <H4><b>Indication</b><br/>KEYTRUDA, in combination with LENVIMA, is indicated for the first-line treatment of adult patients with advanced renal cell carcinoma (RCC).</H4>
            </PageIntro>
            <Section title='Study Design and BASELINE Characteristics' bgColor='cloud' id='study-design'>
                <SectionIntro>
                    <Subtitle>FOCUS ON STUDY DESIGN</Subtitle>
                    <H4><b>The KEYNOTE-581/CLEAR trial:</b> A multicenter, randomized, open-label, phase 3 trial with 1,069 patients<sup>1</sup></H4>
                    <Body>Studied in the first-line setting across MSKCC risk groups</Body>
                </SectionIntro>
                <Subsection>
                    <Chart imgSrc={mobileChart} desktopImgSrc={desktopChart} noTitle showNote imgAlt='Study Design for KEYNOTE-581/CLEAR Clinical Trial'>
                    </Chart>
                    <RccFootnote>
                        <p><sup>a</sup>North America and Western Europe vs “Rest of the World.”</p>
                        <p><sup>b</sup>Randomization was stratified according to Memorial Sloan Kettering Cancer Center (MSKCC) prognostic risk groups: favorable vs intermediate vs poor.</p>
                        <p><sup>c</sup>Clinical data are presented from the KEYTRUDA + LENVIMA and sunitinib arms.</p>
                        <p><sup>d</sup>Administration of KEYTRUDA with LENVIMA was permitted beyond RECIST-defined disease progression, if the patient was clinically stable and considered by the investigator to be deriving clinical benefit.</p>
                        <p>1L = first-line; IRC = independent radiologic review committee; ORR = objective response rate; OS = overall survival; PD-L1 = programmed death ligand 1; PFS = progression-free survival; RECIST v1.1 = Response Evaluation Criteria In Solid Tumors v1.1.</p>
                    </RccFootnote>
                </Subsection>
                <Subsection>
                    <Subtitle>FOCUS ON BASELINE CHARACTERISTICS</Subtitle>
                    <RccTable type='default'>
                        <H6>Baseline characteristics (N=1,069)<sup>1</sup></H6>
                        <TableRow>
                            <Body><b>Median age</b></Body>
                            <Body><b>62 years</b> (range: 29 to 88 years; 42% aged 65 or older)</Body>
                        </TableRow>
                        <TableRow>
                            <Body><b>Gender</b></Body>
                            <Body><b>75%</b> Male</Body>
                        </TableRow>
                        <TableRow>
                            <Body><b>Race</b></Body>
                            <TableColumns>
                                <Body><b>74%</b> White</Body>
                                <Body><b>21%</b> Asian</Body>
                                <Body><b>1%</b> Black</Body>
                                <Body><b>2%</b> Other</Body>
                            </TableColumns>
                        </TableRow>
                        <TableRow>
                            <Body><b>Baseline Karnofsky performance status</b></Body>
                            <TableColumns>
                                <Body><b>18%</b> 70 to 80</Body>
                                <Body><b>82%</b> 90 to 100</Body>
                            </TableColumns>
                        </TableRow>
                        <TableRow>
                            <Body><b>Common sites of metastases</b></Body>
                            <TableColumns className='second-cell-expanded'>
                                <Body><b>68%</b> Lung</Body>
                                <Body><b>45%</b> Lymph node</Body>
                                <Body><b>25%</b> Bone</Body>
                            </TableColumns>
                        </TableRow>
                        <TableRow>
                            <Body><b>Distribution of MSKCC risk categories</b></Body>
                            <TableColumns className='second-cell-expanded'>
                                <Body><b>27%</b> Favorable</Body>
                                <Body><b>64%</b> Intermediate</Body>
                                <Body><b>9%</b> Poor</Body>
                            </TableColumns>
                        </TableRow>
                        <TableRow>
                            <Reference>MSKCC = Memorial Sloan Kettering Cancer Center.</Reference>
                        </TableRow>
                    </RccTable>
                    <RccPrimaryCallout buttonText='Learn More' buttonTo='https://www.keytrudalenvimahcp.com/static/pdfs/patient-profile-brochure-rcc.pdf' iconType='download'>
                        <div>Explore profiles of hypothetical patients who may be appropriate&nbsp;for <keytitle>KEYTRUDA</keytitle>&nbsp;+&nbsp;<lentitle>LENVIMA</lentitle>.</div>
                    </RccPrimaryCallout>
                </Subsection>
            </Section>
            <Section title='EFFICACY' id='efficacy'>
                <RccEfficacyIntro>
                    <H3>In the KEYNOTE-581/CLEAR trial: <b><keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle></b> demonstrated superiority in PFS, OS, and ORR vs sunitinib at protocol-specified interim analysis</H3>
                    <SectionIntro>
                        <Subtitle>FOCUS ON PROGRESSION-FREE SURVIVAL</Subtitle>
                        <H4><b>Superior PFS with <keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle></b> vs sunitinib <b>(HR<sup>e</sup>=0.39; 95% CI, 0.32–0.49; <i>P</i><sup>f</sup>&lt;0.0001)</b> at protocol-specified interim analysis</H4>
                        <Ul marker={colors.charcoal}>
                            <li>Number of events<sup>g</sup>: 160/355 (45%) with KEYTRUDA + LENVIMA vs 205/357 (57%) with sunitinib; Progressive disease: 145/355 (41%) vs 196/357 (55%), respectively; Death: 15/355 (4%) vs 9/357 (3%), respectively.</li>
                            <li><b>Median PFS: 23.9 months</b> (95% CI, 20.8–27.7) with KEYTRUDA + LENVIMA vs <b>9.2 months</b> (95% CI, 6.0–11.0) with sunitinib.</li>
                            <li>PFS and OS were major endpoints in the KEYNOTE-581/CLEAR trial.</li>
                        </Ul>
                        <Reference>HR = hazard ratio; CI = confidence interval.</Reference>
                    </SectionIntro>
                </RccEfficacyIntro>
                <Subsection>
                    <RCCEfficacyCallout bg='mist'>
                        <div>
                            <b>2.5x greater median PFS</b> observed with <keytitle><b>KEYTRUDA</b></keytitle> + <lentitle><b>LENVIMA</b></lentitle> (23.9&nbsp;months) vs sunitinib (9.2 months).
                        </div>
                    </RCCEfficacyCallout>
                    <Chart imgSrc={desktopEfficacyChart} desktopImgSrc={desktopEfficacyChart} showNote imgAlt='KEYNOTE-581/CLEAR Trial: KM Estimates of Progression Free Survival with KEYTRUDA® (pembrolizumab) and LENVIMA® (lenvatinib) (n=355) vs sunitinib (n=357) at the Protocol-specified interim analysis'>
                        <H6>Kaplan-Meier estimates of PFS with <keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle> (n=355) vs sunitinib (n=357) in the KEYNOTE&#8209;581/CLEAR trial</H6>
                        <RccFootnote>
                            <p><sup>e</sup>Hazard ratio is based on a Cox Proportional Hazards Model. Stratified by geographic region and MSKCC prognostic groups.</p>
                            <p><sup>f</sup>Two-sided <i>P</i> value based on stratified log-rank test.</p>
                            <p><sup>g</sup>Tumor assessments were based on RECIST v1.1; data cutoff date = 28 Aug 2020.</p>
                        </RccFootnote>
                    </Chart>
                </Subsection>
                <Subsection>
                    <SectionIntro>
                        <Subtitle>FOCUS ON OVERALL SURVIVAL</Subtitle>
                        <H4><b>Durable overall survival with <keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle></b> vs sunitinib</H4>
                    </SectionIntro>
                    <OSSection>
                        <H6>Superior OS with <b><keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle></b> vs sunitinib demonstrated at protocol-specified interim analysis</H6>
                        <TextIcon2Column>
                            <img src={rccOsIcon} alt='' />
                            <Ul marker={colors.charcoal}>
                                <li><b>HR<sup>e</sup>=0.66; 95% CI, 0.49–0.88; <i>P</i><sup>f</sup>=0.0049.</b></li>
                                <li>Number of deaths<sup>h</sup>: 80/355 (23%) with KEYTRUDA + LENVIMA vs 101/357 (28%) with sunitinib.</li>
                                <li><b>Median OS was not reached (NR) in either arm:</b> KEYTRUDA + LENVIMA (95% CI, 33.6–NR) and sunitinib (95% CI, NR–NR).</li>
                                <li>OS and PFS were major endpoints in the KEYNOTE-581/CLEAR trial.</li>
                            </Ul>
                        </TextIcon2Column>
                        <RccFootnote>
                            <p><sup>e</sup>Hazard ratio is based on a Cox Proportional Hazards Model. Stratified by geographic region and MSKCC prognostic groups.</p>
                            <p><sup>f</sup>Two-sided <i>P</i> value based on stratified log-rank test.</p>
                            <p><sup>h</sup>Data cutoff date = 28 Aug 2020.</p>
                        </RccFootnote>
                    </OSSection>
                    <OSSection>
                        <H6>Updated <b>OS<sup>i,j</sup> at protocol-specified final analysis</b></H6>
                        <OSContent>
                            <Body>This protocol-specified final analysis occurred after the interim analysis, which demonstrated the superiority of OS with KEYTRUDA + LENVIMA vs sunitinib. No statistical testing was planned for the protocol-specified final OS analysis.</Body>
                        </OSContent>
                        <Chart imgSrc={rccOsChartMobile} desktopImgSrc={rccOsChartDesktop} imgAlt='KEYNOTE-581/CLEAR Trial: Estimates of Updated OS With KEYTRUDA (pembrolizumab) + LENVIMA (lenvatinib) (n=355) vs sunitinib (n=357) at the Protocol-specified Final Analysis'>
                            <H6>Kaplan-Meier estimates of updated OS with <keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle> (n=355) vs sunitinib (n=357) in the KEYNOTE&#8209;581/CLEAR trial</H6>
                        </Chart>
                        <Ul marker={colors.charcoal}>
                            <li><b>HR<sup>e</sup>=0.79 (95% CI, 0.63–0.99).</b></li>
                            <li>Number of deaths<sup>j</sup>: 149/355 (42%) with KEYTRUDA + LENVIMA vs 159/357 (45%) with sunitinib.</li>
                            <li><b>Median OS: 53.7 months</b> (95% CI, 48.7–NR) with KEYTRUDA + LENVIMA vs <b>54.3 months</b> (95% CI, 40.9–NR) with sunitinib.</li>
                        </Ul>
                        <RccFootnote>
                            <p><sup>e</sup>Hazard ratio is based on a Cox Proportional Hazards Model. Stratified by geographic region and MSKCC prognostic groups.</p>
                            <p><sup>i</sup>An updated OS analysis was conducted when 304 deaths were observed based on the planned number of deaths for the prespecified final analysis.</p>
                            <p><sup>j</sup>Updated OS cutoff date = 31 July 2022.</p>
                        </RccFootnote>
                    </OSSection>
                </Subsection>
                <Subsection>
                    <SectionIntro>
                        <Subtitle>FOCUS ON OBJECTIVE RESPONSE RATE</Subtitle>
                        <H4>A <b>greater ORR<sup>k</sup></b> was demonstrated with <b><keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle></b>: <b>71% (95% CI, 66–76)</b> vs <b>36% (95% CI, 31–41)</b> with sunitinib <b>(<i>P</i><sup>l</sup>&lt;0.0001)</b> at protocol-specified interim analysis</H4>
                        <Ul marker={colors.charcoal}>
                            <li><b>CR</b> was <b>16%</b> with KEYTRUDA + LENVIMA vs <b>4%</b> with sunitinib.</li>
                            <li><b>PR</b> was <b>55%</b> with KEYTRUDA + LENVIMA vs <b>32%</b> with sunitinib.</li>
                            <li>ORR was an additional endpoint in the KEYNOTE-581/CLEAR trial.</li>
                        </Ul>
                    </SectionIntro>
                    <RCCEfficacyCallout bg='mist'>
                        <div>
                            <b>Nearly 2x greater ORR</b> demonstrated with <keytitle><b>KEYTRUDA</b></keytitle> + <lentitle><b>LENVIMA</b></lentitle> (71%) vs sunitinib (36%) (<i>P</i><sup>l</sup>&lt;0.0001).
                        </div>
                    </RCCEfficacyCallout>
                    <OrrChart imgSrc={mobileOrrChart} desktopImgSrc={desktopOrrChart} showNote className='orr' imgAlt='Response Rates With KEYTRUDA® (pembrolizumab) and LENVIMA® (lenvatinib) (n=355) vs sunitinib (n=357) in the KEYNOTE-581/CLEAR Trial'>
                        <H6>ORR with <keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle> vs sunitinib in the KEYNOTE-581/CLEAR trial<sup>k</sup></H6>
                        <RccFootnote>
                            <p><sup>k</sup>Tumor assessments were based on RECIST v1.1; only confirmed responses are included for ORR. Data cutoff date = 28 Aug 2020.</p>
                            <p><sup>l</sup>Two-sided <i>P</i> value based upon CMH test.</p>
                            <p>CR = complete response; PR = partial response; CMH = Cochran-Mantel-Haenszel.</p>
                        </RccFootnote>
                    </OrrChart>
                </Subsection>
            </Section>
            <ARSection bgColor='cloud' title='Adverse Reactions' id='adverse-reaction'>
                <SectionIntro>
                    <H3><b>Adverse reactions</b> in the KEYNOTE-581/CLEAR trial</H3>
                    <Body>The safety of KEYTRUDA + LENVIMA was investigated in the KEYNOTE-581/CLEAR trial in patients treated with KEYTRUDA + LENVIMA (n=352) compared to sunitinib (n=340) at the protocol-specified interim analysis.</Body>
                    <Ul marker={colors.charcoal}>
                        <li>The median duration of exposure to KEYTRUDA + LENVIMA was 17 months (range: 0.1 to 39).</li>
                    </Ul>
                </SectionIntro>
                <Subsection>
                    <Subtitle>FOCUS ON FATAL AND SERIOUS ADVERSE REACTIONS</Subtitle>
                    <EndoTable type='default' noOfCells='even'>
                        <H6>Fatal adverse reactions occurred in 4.3% of patients treated with <keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle>, including cardio-respiratory arrest (0.9%), sepsis (0.9%), and one case (0.3%) each of:</H6>
                        <ShadedTableRow>
                            <ShadedTableColumn>
                                <Body>Arrhythmia</Body>
                                <Body>Autoimmune hepatitis</Body>
                                <Body>Dyspnea</Body>
                                <Body>Hypertensive crisis</Body>
                                <Body>Increased blood creatinine</Body>
                                <Body>Multiple organ dysfunction syndrome</Body>
                            </ShadedTableColumn>
                            <div className='divider'/>
                            <ShadedTableColumn>
                                <Body>Myasthenic syndrome</Body>
                                <Body>Myocarditis</Body>
                                <Body>Nephritis</Body>
                                <Body>Pneumonitis</Body>
                                <Body>Ruptured aneurysm</Body>
                                <Body>Subarachnoid hemorrhage</Body>
                            </ShadedTableColumn>
                        </ShadedTableRow>
                    </EndoTable>
                    <RccPrimaryCallout className='noBorder'>
                        <div>
                            <p>Serious adverse reactions occurred in 51% of patients receiving <keytitle>KEYTRUDA</keytitle>&nbsp;+&nbsp;<lentitle>LENVIMA</lentitle>.</p>
                        </div>
                    </RccPrimaryCallout>
                    <EndoTable type='default' noOfCells='odd'>
                        <H6>Serious adverse reactions in ≥2% of patients receiving <keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle> were:</H6>
                        <ShadedTableRow>
                            <ShadedTableColumn>
                                <Body>Hemorrhagic events (5%)</Body>
                                <Body>Diarrhea (4%)</Body>
                                <Body>Hypertension (3%)</Body>
                                <Body>Myocardial infarction (3%)</Body>
                                <Body>Pneumonitis (3%)</Body>
                            </ShadedTableColumn>
                            <div className='divider'/>
                            <ShadedTableColumn>
                                <Body>Vomiting (3%)</Body>
                                <Body>Acute kidney injury (2%)</Body>
                                <Body>Adrenal insufficiency (2%)</Body>
                                <Body>Dyspnea (2%)</Body>
                                <Body>Pneumonia (2%)</Body>
                            </ShadedTableColumn>
                        </ShadedTableRow>
                    </EndoTable>
                </Subsection>
                <Subsection>
                    <Subtitle>FOCUS ON PERMANENT DISCONTINUATION, INTERRUPTION, AND DOSE REDUCTION</Subtitle>
                    <DesktopOnly>
                        <RccTable type='default'>
                            <H6>Permanent discontinuation, dose interruption, and dose reduction due to an adverse reaction in the KEYNOTE&#8209;581/CLEAR trial</H6>
                            <EfficacyTableRow>
                                <Body></Body>
                                <PDTableColumns>
                                    <Body><b>Permanent Discontinuation (%)</b></Body>
                                    <Body><b>Dose<br/>Interruption (%)</b></Body>
                                    <Body><b>Dose Reduction (%)</b></Body>
                                </PDTableColumns>
                            </EfficacyTableRow>
                            <EfficacyTableRow>
                                <Body><b><keytitle>KEYTRUDA</keytitle>, <lentitle>LENVIMA</lentitle>, or both</b></Body>
                                <PDTableColumns>
                                    <Body>37</Body>
                                    <Body>78</Body>
                                    <Body>&#8211;</Body>
                                </PDTableColumns>
                            </EfficacyTableRow>
                            <EfficacyTableRow>
                                <Body><b><keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle></b></Body>
                                <PDTableColumns>
                                    <Body>13</Body>
                                    <Body>39</Body>
                                    <Body>&#8211;</Body>
                                </PDTableColumns>
                            </EfficacyTableRow>
                            <EfficacyTableRow>
                                <Body><b><keytitle>KEYTRUDA</keytitle></b></Body>
                                <PDTableColumns>
                                    <Body>29</Body>
                                    <Body>55</Body>
                                    <Body>&#8211;</Body>
                                </PDTableColumns>
                            </EfficacyTableRow>
                            <EfficacyTableRow>
                                <Body><b><lentitle>LENVIMA</lentitle></b></Body>
                                <PDTableColumns>
                                    <Body>26</Body>
                                    <Body>73</Body>
                                    <Body>69</Body>
                                </PDTableColumns>
                            </EfficacyTableRow>
                            <EfficacyTableRow>
                                <Body className='full-width'>No dose reduction for <b><keytitle>KEYTRUDA</keytitle></b> is recommended.</Body>
                            </EfficacyTableRow>
                        </RccTable>
                    </DesktopOnly>
                    <MobileOnly>
                        <PdChart imgSrc={modilePdImage} showNote imgAlt='Permanent Discontinuation, Dose Interruption, and Dose Reduction due to an Adverse Reaction in the KEYNOTE‑581/CLEAR Trial'>
                            <H6>Permanent discontinuation, dose interruption, and dose reduction due to an adverse reaction in the KEYNOTE-581/CLEAR trial</H6>
                            <Body className='full-width'>No dose reduction for <b><keytitle>KEYTRUDA</keytitle></b> is recommended.</Body>
                        </PdChart>
                    </MobileOnly>
                    <EndoTable type='default' noOfCells='odd'>
                        <H6>Most common (≥2%) adverse reactions that resulted in permanent discontinuation of <keytitle>KEYTRUDA</keytitle>, <lentitle>LENVIMA</lentitle>, or&nbsp;both</H6>
                        <ShadedTableRow>
                            <ShadedTableColumn>
                                <Body>Pneumonitis (3%)</Body>
                                <Body>Myocardial infarction (3%)</Body>
                                <Body>Hepatotoxicity (3%)</Body>
                            </ShadedTableColumn>
                            <div className='divider'/>
                            <ShadedTableColumn>
                                <Body>Acute kidney injury (3%)</Body>
                                <Body>Rash (3%)</Body>
                                <Body>Diarrhea (2%)</Body>
                            </ShadedTableColumn>
                        </ShadedTableRow>
                    </EndoTable>
                    <EndoTable type='keytruda' noOfCells='odd'>
                        <H6>Most common (≥3%) adverse reactions in patients receiving <keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle> that resulted in interruption of <keytitle>KEYTRUDA</keytitle></H6>
                        <ShadedTableRow>
                            <ShadedTableColumn>
                                <Body>Diarrhea (10%)</Body>
                                <Body>Hepatotoxicity (8%)</Body>
                                <Body>Fatigue (7%)</Body>
                                <Body>Lipase increased (5%)</Body>
                                <Body>Amylase increased (4%)</Body>
                            </ShadedTableColumn>
                            <div className='divider'/>
                            <ShadedTableColumn>
                                <Body>Musculoskeletal pain (3%)</Body>
                                <Body>Hypertension (3%)</Body>
                                <Body>Rash (3%)</Body>
                                <Body>Acute kidney injury (3%)</Body>
                                <Body>Decreased appetite (3%)</Body>
                            </ShadedTableColumn>
                        </ShadedTableRow>
                    </EndoTable>
                    <EndoTable type='lenvima' noOfCells='even'>
                        <H6>Most common (≥5%) adverse reactions in patients receiving <keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle> that resulted in dose reduction or interruption of <lentitle>LENVIMA</lentitle></H6>
                        <ShadedTableRow>
                            <ShadedTableColumn>
                                <Body>Diarrhea (26%)</Body>
                                <Body>Fatigue (18%)</Body>
                                <Body>Hypertension (17%)</Body>
                                <Body>Proteinuria (13%)</Body>
                                <Body>Decreased appetite (12%)</Body>
                                <Body>Palmar-plantar erythrodysesthesia (11%)</Body>
                                <Body>Nausea (9%)</Body>
                                <Body>Stomatitis (9%)</Body>
                            </ShadedTableColumn>
                            <div className='divider'/>
                            <ShadedTableColumn>
                                <Body>Musculoskeletal pain (8%)</Body>
                                <Body>Rash (8%)</Body>
                                <Body>Increased lipase (7%)</Body>
                                <Body>Abdominal pain (6%)</Body>
                                <Body>Vomiting (6%)</Body>
                                <Body>Increased ALT (5%)</Body>
                                <Body className='last-element-hidden'>Increased amylase (5%)</Body>
                                <DesktopOnly><Body></Body></DesktopOnly>
                            </ShadedTableColumn>
                        </ShadedTableRow>
                    </EndoTable>
                    <Reference>ALT = alanine aminotransferase.</Reference>
                    <Ul marker={colors.charcoal}><li>Fifteen percent (15%) of patients treated with KEYTRUDA + LENVIMA received an oral prednisone equivalent to ≥40 mg daily for an immune-mediated adverse reaction.</li></Ul>
                </Subsection>
                <Subsection>
                    <Subtitle>FOCUS ON MOST COMMON ADVERSE REACTIONS</Subtitle>
                    <Chart imgSrc={commonArMobile} desktopImgSrc={commonArDesktop} showNote imgAlt='Adverse Reactions That Occurred in ≥20% of Patients Receiving KEYTRUDA® (pembrolizumab) and LENVIMA® (lenvatinib) in the KEYNOTE-581/CLEAR Trial'>
                        <H6>Adverse reactions that occurred in ≥20% of patients receiving <keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle> in the KEYNOTE&#8209;581/CLEAR trial</H6>
                        <RccFootnote>
                            <p><sup>m</sup>Includes asthenia, fatigue, lethargy, and malaise.</p>
                            <p><sup>n</sup>Includes diarrhea and gastroenteritis.</p>
                            <p><sup>o</sup>Includes arthralgia, arthritis, back pain, bone pain, breast pain, musculoskeletal chest pain, musculoskeletal discomfort, musculoskeletal pain, musculoskeletal stiffness, myalgia, neck pain, non-cardiac chest pain, pain in extremity, and pain in jaw.</p>
                            <p><sup>p</sup>Includes hypothyroidism, increased blood thyroid stimulating hormone, and secondary hypothyroidism.</p>
                            <p><sup>q</sup>Includes essential hypertension, increased blood pressure, increased diastolic blood pressure, hypertension, hypertensive crisis, hypertensive retinopathy, and labile blood pressure.</p>
                            <p><sup>r</sup>Includes aphthous ulcer, gingival pain, glossitis, glossodynia, mouth ulceration, mucosal inflammation, oral discomfort, oral mucosal blistering, oral pain, oropharyngeal pain, pharyngeal inflammation, and stomatitis.</p>
                            <p><sup>s</sup>Includes decreased appetite and early satiety.</p>
                            <p><sup>t</sup>Includes genital rash, infusion site rash, penile rash, perineal rash, rash, rash erythematous, rash macular, rash maculo-papular, rash papular, rash pruritic, and rash pustular.</p>
                            <p><sup>u</sup>Includes hemoglobinuria, nephrotic syndrome, and proteinuria.</p>
                            <p><sup>v</sup>Includes palmar erythema, palmar-plantar erythrodysesthesia syndrome, and plantar erythema.</p>
                            <p><sup>w</sup>Includes all hemorrhage terms. Hemorrhage terms that occurred in 1 or more subjects in either treatment group include anal hemorrhage, aneurysm ruptured, blood blister, blood loss anemia, blood urine present, catheter site hematoma, cerebral microhemorrhage, conjunctival hemorrhage, contusion, diarrhea hemorrhagic, disseminated intravascular coagulation, ecchymosis, epistaxis, eye hemorrhage, gastric hemorrhage, gastritis hemorrhagic, gingival bleeding, hemorrhage urinary tract, hemothorax, hematemesis, hematoma, hematochezia, hematuria, hemoptysis, hemorrhoidal hemorrhage, increased tendency to bruise, injection site hematoma, injection site hemorrhage, intra-abdominal hemorrhage, lower gastrointestinal hemorrhage, Mallory-Weiss syndrome, melaena, petechiae, rectal hemorrhage, renal hemorrhage, retroperitoneal hemorrhage, small intestinal hemorrhage, splinter hemorrhages, subcutaneous hematoma, subdural hematoma, subarachnoid hemorrhage, thrombotic thrombocytopenic purpura, tumor hemorrhage, traumatic hematoma, and upper gastrointestinal hemorrhage.</p>
                            <p><sup>x</sup>Includes abdominal discomfort, abdominal pain, abdominal rigidity, abdominal tenderness, epigastric discomfort, lower abdominal pain, and upper abdominal pain.</p>
                            <p><sup>y</sup>Includes alanine aminotransferase increased, aspartate aminotransferase increased, blood bilirubin increased, drug-induced liver injury, hepatic enzyme increased, hepatic failure, hepatic function abnormal, hepatocellular injury, hepatotoxicity, hyperbilirubinemia, hypertransaminasemia, immune-mediated hepatitis, liver function test increased, liver injury, transaminases increased, and gamma-glutamyltransferase increased.</p>
                            <p><sup>z</sup>Includes acute kidney injury, azotemia, blood creatinine increased, creatinine renal clearance decreased, hypercreatininemia, renal failure, renal impairment, oliguria, glomerular filtration rate decreased, and nephropathy toxic.</p>
                        </RccFootnote>
                    </Chart>
                    <Ul marker={colors.charcoal}>
                        <li>Clinically relevant adverse reactions (&lt;20%) that occurred in patients receiving KEYTRUDA + LENVIMA were myocardial infarction (3%) and angina pectoris (1%).</li>
                        <li>Grade 3 and 4 increased ALT or AST was seen in 9% of patients. Grade ≥2 increased ALT or AST was reported in 64 (18%) patients, of whom 20 (31%) received ≥40 mg daily oral prednisone equivalent. Recurrence of Grade ≥2 increased ALT or AST was observed on rechallenge in 3 patients receiving LENVIMA, in 10 patients receiving both KEYTRUDA and LENVIMA (n=38), and was not observed on rechallenge with KEYTRUDA alone (n=3).</li>
                    </Ul>
                    <Reference>
                        AST = aspartate aminotransferase.
                    </Reference>
                </Subsection>
            </ARSection>
            <FDASection>
                <SectionIntro>
                    <H6><b>FDA-APPROVED INDICATION</b><br/>KEYTRUDA, in combination with LENVIMA, is indicated for the first-line treatment of adult patients with advanced renal cell carcinoma (RCC).</H6>
                </SectionIntro>
                <Subsection></Subsection>
            </FDASection>
            <NccnSection title='NCCN RECOMMENDATION' id='nccn-recommendation'>
                <SectionIntro>
                    <NccnCallout noIcon>
                        <p><b>National Comprehensive Cancer Network® (NCCN®)</b> recommends pembrolizumab (<keytitle><b>KEYTRUDA</b></keytitle>) + lenvatinib (<lentitle><b>LENVIMA</b></lentitle>) as a <b>PREFERRED</b> first-line therapy option (<b>CATEGORY 1</b>) for advanced clear cell renal cell carcinoma.<sup>2</sup></p>
                    </NccnCallout>
                </SectionIntro>
                <Subsection>
                    <DesktopOnly>
                        <RccTable type='default'>
                            <H6>NCCN recommendation according to MSKCC and IMDC risk categories<sup>2</sup></H6>
                            <EfficacyTableRow>
                                <Body></Body>
                                <PDTableColumns>
                                    <Body><b>Favorable</b></Body>
                                    <Body><b>Intermediate</b></Body>
                                    <Body><b>Poor</b></Body>
                                </PDTableColumns>
                            </EfficacyTableRow>
                            <EfficacyTableRow>
                                <Body><b><keytitle>Pembrolizumab (KEYTRUDA)</keytitle> + <lentitle>Lenvatinib (LENVIMA)</lentitle></b></Body>
                                <PDTableColumns>
                                    <Body><b>PREFERRED<br/><TableSpan>CATEGORY 1</TableSpan></b></Body>
                                    <Body><b>PREFERRED<br/><TableSpan>CATEGORY 1</TableSpan></b></Body>
                                    <Body><b>PREFERRED<br/><TableSpan>CATEGORY 1</TableSpan></b></Body>
                                </PDTableColumns>
                            </EfficacyTableRow>
                        </RccTable>
                    </DesktopOnly>
                    <MobileOnly>
                        <NccnTableMobile type='default'>
                            <H6>NCCN recommendation according to MSKCC and IMDC risk categories<sup>2</sup></H6>
                            <EfficacyTableRow>
                                <Body></Body>
                                <EfficacyTableColumns>
                                    <Body><b><keytitle>Pembrolizumab (KEYTRUDA)</keytitle> +<br/><lentitle>Lenvatinib (LENVIMA)</lentitle></b></Body>
                                </EfficacyTableColumns>
                            </EfficacyTableRow>
                            <EfficacyTableRow>
                                <Body><b>Favorable</b></Body>
                                <EfficacyTableColumns>
                                    <Body><b>PREFERRED<br/><TableSpan>CATEGORY 1</TableSpan></b></Body>
                                </EfficacyTableColumns>
                            </EfficacyTableRow>
                            <EfficacyTableRow>
                                <Body><b>Intermediate</b></Body>
                                <EfficacyTableColumns>
                                    <Body><b>PREFERRED<br/><TableSpan>CATEGORY 1</TableSpan></b></Body>
                                </EfficacyTableColumns>
                            </EfficacyTableRow>
                            <EfficacyTableRow>
                                <Body><b>Poor</b></Body>
                                <EfficacyTableColumns>
                                    <Body><b>PREFERRED<br/><TableSpan>CATEGORY 1</TableSpan></b></Body>
                                </EfficacyTableColumns>
                            </EfficacyTableRow>
                        </NccnTableMobile>
                    </MobileOnly>
                    <Ul marker={colors.charcoal}>
                        <li>Pembrolizumab (KEYTRUDA) + lenvatinib&nbsp;(LENVIMA): A <b>PREFERRED</b> first-line option (<b>CATEGORY 1</b>) for favorable and intermediate/poor-risk patients with advanced clear cell renal cell carcinoma within NCCN for Kidney Cancer<sup>2</sup></li>
                    </Ul>
                    <RccFootnote>
                        <p>NCCN makes no warranties of any kind whatsoever regarding their content, use, or application and disclaims any responsibility for their application or use in any way.</p>
                        <div><p>Category 1: Based upon high-level evidence, there is uniform NCCN consensus that the intervention is appropriate.<sup>2</sup></p></div>
                        <p>IMDC = International Metastatic Renal Cell Carcinoma Database Consortium.</p>
                    </RccFootnote>
                </Subsection>
            </NccnSection>
            <RelatedContent title='Learn more about adverse reaction management and dosing' links={RCLinks}/>
            <Section noIntro>
                <RccReference>
                    <Reference>
                        <b>References: 1.</b> Motzer R, Alekseev B, Rha SY, et al. Lenvatinib plus pembrolizumab or everolimus for advanced renal cell carcinoma. <i>N Engl J Med.</i> 2021;384(14):1289–1300. <b>2.</b> Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Kidney Cancer V.2.2024. © National Comprehensive Cancer Network, Inc. 2024. All rights reserved. Accessed January 5, 2024. To view the most recent and complete version of the guidelines, go online to NCCN.org. NCCN makes no warranties of any kind whatsoever regarding their content, use, or application and disclaims any responsibility for their application or use in any way.
                    </Reference>
                </RccReference>
            </Section>
        </DefaultLayout>
    )
};

export default Rcc;
