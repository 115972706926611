import React from 'react';
import styled from 'styled-components';
import { respondTo } from '../../util/breakpoints';
import * as colors from '../../util/colors';
import { Body, H5 } from '../../util/typography';
import Button from '../Button';
import arrowRight from '../../assets/utility/icons/icon-button-arrow.svg';
import { arrowRightWhite, downloadBarWhite, downloadArrowWhite } from '../../util/icons';

const splitGradient = (leftColor, rightColor) =>
    `linear-gradient(to right, ${leftColor} 0%, ${leftColor} 50%, ${rightColor} calc(50% + 1px), ${rightColor} 100%)`;

const PrimaryCalloutContainer = styled.section`
    width: 100%;
    position: relative;
    background: ${({bg}) => bg === 'mist'? colors.cloud : colors.white};
    cursor: pointer;
    [class*='__ButtonContainer']:hover{
        background: ${splitGradient(colors.greenDark, colors.greenRollOver)};
        background-position: right center;
        background-size: 201% 100%;
        background-origin: border-box;
        &:after {
            ${({iconType}) => iconType === 'right' ? `
                animation-duration: 0.3s;
                animation-name: arrowRight-animation;
                animation-fill-mode: both;  
            `:`
                animation-duration: 0.3s;
                animation-name: download-animation;
                animation-fill-mode: both;  
            `}
            
        }
    }
    @keyframes download-animation {
        0%,
        100% {
            background-position: bottom center, top center;
        }

        50% {
            background-position: bottom center, center calc(100% + 12px);
        }

        50.001% {
            background-position: bottom center, center calc(0% - 12px);
        }
    }
    @keyframes arrowRight-animation {
        0% {
            background-position: center 50%, left;
        }

        50% {
            background-position: calc(100% + 20px) center, center;
        }

        50.001% {
            background-position: calc(0% - 20px) center, center;
        }

        100% {
            background-position: center 50%, right;
        }
    }
`;

const PrimaryCalloutContent = styled.div`
    padding: 40px 20px 40px 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    text-align: center;
    color: ${colors.charcoal};
    margin: 0 auto;
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 20px;
        margin-right: 20px;
        border-left: 5px solid ${colors.brandGreen};
        background: linear-gradient(157deg, ${colors.mulberry} 40.03%, ${colors.mulberryLight} 107.32%);
    }
    ${respondTo.md`
        max-width: 672px;
        padding: 40px 0;
    `}
`;

const Title = styled(H5)`
    font-weight: 700;
`;

const PCButton = styled(Button)`
    font-weight: 700;
    position: relative;
    padding-right: 44px;
    background-image: ${splitGradient(colors.greenDark, colors.greenRollOver)};
    background-size: 201% 100%;
    background-origin: border-box;
    transition: background-position 0.3s ease-in-out;
    &:after {
        content: '';
        position: absolute;
        right: 16px;
        ${({iconType}) => iconType === 'right' ? `
        background: url(${arrowRightWhite});
        background-position: center left;
        ` : `
        background-image:url(${downloadBarWhite}), url(${downloadArrowWhite});
        background-position: bottom center, top center;
        `};
        background-repeat: no-repeat;
        top: 50%;
        transform: translateY(-50%);
        width: 12px;
        height: 16px;
    }
`;


const PrimaryCallout = ({children, buttonText, bg, className, buttonTo, iconType = 'right'}) => {
    return (
        <PrimaryCalloutContainer bg={bg} iconType={iconType}>
            <PrimaryCalloutContent className={className}>
                {children && !Array.isArray(children) ? <Title>{children}</Title> : children && <Title>{children[0]}</Title>}
                {children && Array.isArray(children) && children.length > 1 ? <Body>{children.slice(1)}</Body> : <></>}
                {buttonText && <PCButton iconType={iconType} type='primary-dark' to={buttonTo} noIcon target='_blank'>{buttonText}</PCButton>}
            </PrimaryCalloutContent>
        </PrimaryCalloutContainer>
)};
export default PrimaryCallout;